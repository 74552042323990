import "bootstrap/js/dist/modal";
import { cardClickModalInfo } from "./includes/cardClickModalInfo.js";
import { hamburgerMenu } from "./includes/hamburgerMenu.js";
import { navMobileDropdowns } from "./includes/navMobileDropdowns.js";
import { navHighlightCurrentItem } from "./includes/navHighlightCurrentItem.js";
import { scrollReveals } from "./includes/scrollReveals.js";
import { smoothAnchorScrollsOnPage } from "./includes/smoothAnchorScrollsOnPage.js";
import { smoothAnchorScrollsOtherPage } from "./includes/smoothAnchorScrollsOtherPage.js";
import { articleSearch } from "./includes/articleSearch.js";
import { articleSearchPanelToggle } from "./includes/articleSearchPanelToggle.js";

jQuery.noConflict();
(function ($) {
  $(document).ready(function () {
    cardClickModalInfo($);
    hamburgerMenu($);
    navMobileDropdowns($);
    navHighlightCurrentItem($);
    scrollReveals($);
    smoothAnchorScrollsOnPage($);
    smoothAnchorScrollsOtherPage($);
    articleSearch($);
    articleSearchPanelToggle($);
  });
})(jQuery);
