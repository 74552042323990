export function scrollReveals($) {
  const $html = $("html, body");

  if ($html.hasClass("scroll-reveals")) {
    ScrollOut({
      threshold: 0.2,
      once: true,
    });
  }
}
