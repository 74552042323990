export function smoothAnchorScrollsOtherPage($) {
  const $html = $("html, body");

  if (!window.location.hash) {
    return;
  }

  // Void some browsers issue
  setTimeout(function () {
    scroll(0, 0);
  }, 1);

  const target = $(window.location.hash);

  if (target.length <= 0) {
    return;
  }

  // Smooth scroll to the anchor id
  $html.animate(
    {
      scrollTop: target.offset().top,
    },
    500
  );
}
