export function hamburgerMenu($) {
  const $window = $(window);
  const $body = $("body");
  const hamburgerMenu = $(".js-hamburger-menu");
  const hamburger = $(".js-hamburger");
  const header = $(".js-header");

  const toggleMobileMenu = function (state) {
    hamburgerMenu.toggleClass("active", state);
    hamburger.toggleClass("clicked", state);
    header.toggleClass("hamburger-open", state);
    $body.toggleClass("overflow-hidden", state);
  };

  hamburger.click(function (e) {
    e.stopPropagation();
    toggleMobileMenu();
  });

  setTimeout(function () {
    hamburgerMenu.removeClass("d-none").addClass("d-flex");
  }, 1000);

  if (typeof window.ResizeObserver !== "function") {
    return;
  }

  const resizeObserver = new ResizeObserver(() => {
    toggleMobileMenu(false);
  });

  resizeObserver.observe(document.querySelector("body"));
}
