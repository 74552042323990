export function navMobileDropdowns($) {
  const addToggles = () => {
    const $submenu = $(
      ".js-nav-mobile > .js-nav-mobile--item > .js-nav-mobile--submenu"
    );

    // Add toggles and hide menus.
    $submenu.each((index, e) => {
      const $toggle = $(
        '<div class="navigation--toggle js-nav-mobile--toggle"><span class="navigation--toggle--chevron"></span></div>'
      );
      const $subMenu = $(e);
      $subMenu.after($toggle).css("display", "none");
    });
  };

  const toggleAction = ($toggle) => {
    $toggle.toggleClass("open");
    $toggle.siblings(".js-nav-mobile--submenu").each((index, e) => {
      $(e).slideToggle();
    });
  };

  const bindToggleActions = () => {
    const $toggles = $(
      ".js-nav-mobile > .js-nav-mobile--item > .js-nav-mobile--toggle"
    );
    $toggles.each((index, e) => {
      const $toggle = $(e);

      $toggle.on("click touch", (toggle) => {
        toggleAction($toggle);
      });
    });
  };

  // Expand the parent menu item for the current page.
  const expandParentForCurrentPage = () => {
    const $active = $(".js-nav-mobile--link.active");

    if ($active.length < 1) return;

    const $submenu = $active.closest(".js-nav-mobile--submenu");

    if ($submenu.length < 1) return;

    const $toggle = $submenu.siblings(".js-nav-mobile--toggle");

    if ($toggle.length < 1) return;

    toggleAction($toggle);
  };

  addToggles();
  bindToggleActions();
  expandParentForCurrentPage();
}
