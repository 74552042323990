import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
  searchBox,
  refinementList,
  hits,
  pagination,
  configure,
  clearRefinements,
} from "instantsearch.js/es/widgets";

/**
 * Card and badge markup is maintained in two places.
 * - here, for the search results.
 * - in twig for the latest articles and other blocks.
 */

export function articleSearch($) {
  const $search = $(".js-articles-search");

  if ($search.length < 1) return;

  const algoliaIndexName = $search.data("index");
  const algoliaApplicationId = $search.data("application-id");
  const algoliaSearchKey = $search.data("search-key");
  const showBadges = $search.data("show-badges");
  const badgeLimit = $search.data("card-badge-limit");
  const brandCategories = $search.data("brands");

  if (!algoliaIndexName || !algoliaApplicationId || !algoliaSearchKey) return;

  const $searchBox = $search.find(".js-articles-search-box");
  const $clearRefinementsBox = $search.find(".js-articles-clear-filters");
  const $badgeBrandsBox = $search.find(".js-articles-badge-brands");
  const $badgeTopicsBox = $search.find(".js-articles-badge-topics");
  const $yearBox = $search.find(".js-articles-year");
  const $resultsBox = $search.find(".js-articles-results");
  const $paginationBox = $search.find(".js-articles-pagination");

  if (
    $searchBox.length < 1 ||
    $clearRefinementsBox.length < 1 ||
    $badgeBrandsBox.length < 1 ||
    $badgeTopicsBox.length < 1 ||
    $yearBox.length < 1 ||
    $resultsBox.length < 1 ||
    $paginationBox.length < 1
  )
    return;

  const search = instantsearch({
    indexName: algoliaIndexName,
    routing: true,
    stalledSearchDelay: 200,
    searchClient: algoliasearch(algoliaApplicationId, algoliaSearchKey),
  });

  const imageMarkup = (item) => {
    return `
    <picture class="block--article-landing-articles--image align-self-end w-100">
      <source media="(min-width: 1856px)" data-srcset="${item.imagePath}/_400x300_crop_center-center_none/${item.imageFilename}" srcset="${item.imagePath}/_400x300_crop_center-center_none/${item.imageFilename}">
      <source media="(min-width: 1200px)" data-srcset="${item.imagePath}/_350x262_crop_center-center_none/${item.imageFilename}" srcset="${item.imagePath}/_350x262_crop_center-center_none/${item.imageFilename}">
      <source media="(min-width: 992px)" data-srcset="${item.imagePath}/_550x412_crop_center-center_none/${item.imageFilename}" srcset="${item.imagePath}/_550x412_crop_center-center_none/${item.imageFilename}">
      <source media="(min-width: 768px)" data-srcset="${item.imagePath}/_400x300_crop_center-center_none/${item.imageFilename}" srcset="${item.imagePath}/_400x300_crop_center-center_none/${item.imageFilename}">
      <source media="(min-width: 576px)" data-srcset="${item.imagePath}/_550x412_crop_center-center_none/${item.imageFilename}" srcset="${item.imagePath}/_550x412_crop_center-center_none/${item.imageFilename}">
      <source media="(max-width: 575px)" data-srcset="${item.imagePath}/_550x412_crop_center-center_none/${item.imageFilename}" data-scroll="out" srcset="${item.imagePath}/_550x412_crop_center-center_none/${item.imageFilename}">
      <img data-src="${item.imagePath}/_350x262_crop_center-center_none/${item.imageFilename}" alt="${item.imageFilenameAlt}" class="w-100 lazyloaded" loading="lazy" src="${item.imagePath}/_350x262_crop_center-center_none/${item.imageFilename}">
    </picture>`;
  };

  const generateBadgeLinksArray = (badges, facetName) => {
    const badgeLinks = [];
    badges.forEach((badgeName) => {
      const refinementListValue = {};
      refinementListValue[facetName] = [badgeName];

      const queryString = {};
      queryString[algoliaIndexName] = {
        refinementList: refinementListValue,
      };
      badgeLinks.push({
        title: badgeName,
        queryString: $.param(queryString),
      });
    });

    return badgeLinks;
  };

  const badgesMarkup = (item) => {
    if (typeof showBadges == "undefined") return "";

    if (item.brands.length < 1 && item.topics.length < 1) return "";

    const brandsBadges = generateBadgeLinksArray(item.brands, "brands");
    const topicsBadges = generateBadgeLinksArray(item.topics, "topics");

    const allBadges = [...brandsBadges, ...topicsBadges];

    let badges = `<ul class="four-up--card--badges d-flex flex-wrap justify-content-start align-items-center">`;

    allBadges.slice(0, badgeLimit).forEach((badge) => {
      badges += `
      <li class="four-up--card--badge badge">
          <a href="${item.parentPageUrl}?${badge.queryString}#searchFilters" class="four-up--card--badge--link badge--link d-inline-block">${badge.title}</a>
      </li>`;
    });

    badges += `</ul>`;

    return badges;
  };

  const articleCardTemplate = (item) => {
    return `
      <div class="four-up--card">
          <div class="d-block js-card">
              <div class="four-up--card--top">
                ${imageMarkup(item)}
              </div>

              ${badgesMarkup(item)}

              <div class="four-up--card--content">
                  <h3 class="h5 four-up--card--title">
                      <a class="four-up--card--link  d-block js-card" href="${
                        item.link
                      }" ${item.linkTarget}>${item.title}</a>
                  </h3>

                  <p class="tiny four-up--card--smallText four-up--card--post-date">${
                    item.postDate
                  }</p>

                  <div class="stretch-arrow d-inline-block">
                      <div class="stretch-arrow--neck"></div>
                      <div class="stretch-arrow--head"></div>
                  </div>
              </div>
          </div>
      </div>
      `;
  };

  const arraySwapKeysValues = (arr) => {
    const out = {};

    Object.keys(arr).forEach((key) => {
      out[arr[key]] = key;
    });

    return out;
  };

  const brandOrder = arraySwapKeysValues(brandCategories);
  const sortBrand = (a, b) => {
    const aOrder = brandOrder[a.name] ?? -1;
    const bOrder = brandOrder[b.name] ?? -1;
    return aOrder > bOrder ? 1 : -1;
  };

  const refinementListClasses = {
    root: "search-filters--filters--refinement",
    list: "search-filters--filters--refinement--list d-flex flex-wrap",
    item: "search-filters--filters--refinement--item",
    selectedItem: "search-filters--filters--refinement--selected-item",
    label: "search-filters--filters--refinement--label",
    checkbox: "search-filters--filters--refinement--checkbox",
    labelText: "search-filters--filters--refinement--label-text label-text",
    count: "search-filters--filters--refinement--count",
  };

  search.addWidgets([
    configure({
      hitsPerPage: 12,
    }),
    // TODO: later after mvp of search page.
    // searchBox({
    //   container: $searchBox[0],
    //   showLoadingIndicator: true,
    //   searchAsYouType: false,
    //   showReset: true,
    // }),
    clearRefinements({
      container: $clearRefinementsBox[0],
      templates: {
        resetLabel: "Clear all filters",
      },
    }),
    refinementList({
      container: $badgeBrandsBox[0],
      attribute: "brands",
      operator: "or",
      sortBy: sortBrand,
      cssClasses: refinementListClasses,
    }),
    refinementList({
      container: $badgeTopicsBox[0],
      attribute: "topics",
      operator: "or",
      cssClasses: refinementListClasses,
    }),
    refinementList({
      container: $yearBox[0],
      attribute: "year",
      operator: "and",
      cssClasses: refinementListClasses,
    }),
    hits({
      container: $resultsBox[0],
      templates: {
        item: articleCardTemplate,
      },
      cssClasses: {
        root: "container",
        list: ["row four-up"],
        item: ["col-12 col-md-6 col-xl-3 four-up--column"],
      },
    }),
    pagination({
      container: $paginationBox[0],
      scrollTo: false,
      cssClasses: {
        root: "",
        list: "pagination d-flex align-items-center justify-content-center",
        item: "pagination--item",
        firstPageItem: "pagination--item--first icon page-first",
        lastPageItem: "pagination--item--last icon page-last",
        previousPageItem: "pagination--item--prev icon page-prev",
        nextPageItem: "pagination--item--next icon page-next ",
        pageItem: "pagination--item--number",
        selectedItem: "pagination--item--current",
        disabledItem: "d-none",
        link: "pagination--link d-flex align-items-center justify-content-center",
      },
    }),
  ]);

  search.start();
}
