export function articleSearchPanelToggle($) {
  const $search = $(".js-articles-search");

  if ($search.length < 1) return;

  const algoliaIndexName = $search.data("index");

  if (!algoliaIndexName) return;

  const $filtersToggle = $search.find(".js-articles-filters-toggle");
  const $filtersPanel = $search.find(".js-articles-filters-panel");

  if ($filtersToggle.length < 1 || $filtersPanel.length < 1) {
    return;
  }

  const togglePanel = () => {
    $filtersToggle.toggleClass("open");

    const toggleLabel = $filtersToggle.hasClass("open")
      ? "Hide filters"
      : "Show filters";

    $filtersToggle.text(toggleLabel);

    $filtersPanel.slideToggle();
  };

  const bindToggle = () => {
    $filtersToggle.on("click touch", togglePanel);
  };

  const leavePanelOpenIfActiveSearch = () => {
    if (window.location.search.indexOf(algoliaIndexName) != -1) return;

    togglePanel();
  };

  bindToggle();
  leavePanelOpenIfActiveSearch();
}
