export function cardClickModalInfo($) {
  const cardsModal = $("#cardsModal");

  if (cardsModal.length < 1) {
    return;
  }

  $('.js-card[data-bs-target="#cardsModal"]').on(
    "click touch",
    function (event) {
      const cardData = $(this).data("card");

      cardsModal
        .find(".cards-modal--image img")
        .attr({ src: cardData.image, alt: cardData.title });
      cardsModal.find(".cards-modal--title").html(cardData.title);
      cardsModal.find(".cards-modal--text").html(cardData.text);
      cardsModal.find(".cards-modal--body").html(cardData.body);
    }
  );
}
