export function navHighlightCurrentItem($) {
  const $active = $(".js-nav--link.active");

  if ($active.length < 1) return;

  const $submenu = $active.closest(".js-nav--submenu");

  if ($submenu.length < 1) return;

  const $parentLink = $submenu.siblings(".js-nav--link");

  if ($parentLink.length < 1) return;

  $parentLink.addClass("active");
}
